import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { share, take } from 'rxjs/operators';
import { BusInfoCityResponse } from 'src/app/models/bus-info/bus-info-city.interface';
import { BusInfoTimeResponse } from 'src/app/models/bus-info/bus-info-time.interface';
import { FamilyResponse } from 'src/app/models/family/family.interface';
import { TripType } from 'src/app/models/guest/bus-info.interface';
import { CreateCompanionRequest, GuestResponse } from 'src/app/models/guest/guest.interface';
import { CreateSongRequest, SongResponse } from 'src/app/models/song/song.interface';
import { UserService } from 'src/app/services/auth/user.service';
import { BusInfoService } from 'src/app/services/bus-info/bus-info.service';
import { FamilyService } from 'src/app/services/family/family.service';
import { FeedInfoService } from 'src/app/services/feed-info/feed-info.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { MessagesService } from 'src/app/services/messages/message.service';
import { ModalService } from 'src/app/services/shared/modal.service';
import { SongService } from 'src/app/services/songs/song.service';

@Component({
  selector: 'app-rsvp-data',
  templateUrl: './rsvp-data.component.html',
  styleUrls: ['./rsvp-data.component.css']
})
export class RsvpDataComponent implements OnInit, AfterViewInit {
  private _environtment;
  public family: FamilyResponse = { id:'', name: '', guests: [], messages: [] };
  public guestSelected: GuestResponse = null;
  public isGuestSelected = false;
  public busInfoCities: BusInfoCityResponse[];
  public busInfoTimes: BusInfoTimeResponse[];
  public selectedCityGo: string;
  public selectedCityBack: string;
  public selectedTimeBack: string;
  public feedInfoComments: string;
  public message: string;
  public feedInfoCommentsAlertClosed = true;
  public sendMessageForm = false;
  public songs: SongResponse[];
  public sendSongForm = false;
  public songErrorAlertClosed = true;
  public songErrorMessage: string = "";
  public messageErrorAlertClosed = true;
  public messageErrorMessage: string = "";
  public busInfoErrorAlertClosed = true;
  public busInfoErrorMessage: string = "";
  public song : CreateSongRequest = { artist: "", title: "" };
  public feedInfoCommentsForm;
  public feedInfoCommentsVisibility = false;
  public sendCompanionForm = false;
  public changedAttendance = false;
  public remainingDays: number;
  public companion: CreateCompanionRequest = { name: "", firstSurName: "", lastSurName: "" };
  public pdfViewerOpened = true;

  constructor(private _familyService: FamilyService, private _guestService: GuestService, private _songService: SongService,
    private _feedInfoService: FeedInfoService, private _userService: UserService, private _busInfoService: BusInfoService, private _translocoService: TranslocoService,
    private _messagesServices: MessagesService, public modalService: ModalService, private translocoService: TranslocoService, @Inject('environment') environment) 
    {
      this._environtment = environment 
    }

  ngOnInit() {
    this.calculateRemainingDays();
    this.getFamily();
    this.getCities();
    this.getSongs();
  }

  ngAfterViewInit() {
    this.feedInfoCommentsForm = new FormGroup({
      feedInfoCommentsControl: new FormControl('', Validators.required)
    });
  }

  calculateRemainingDays() {
    const currentDate = new Date();
    const targetDate = new Date(this._environtment.date);
    const difference = targetDate.getTime() - currentDate.getTime();

    this.remainingDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  }

  async getFamily(){
    this._familyService.getFamily().pipe(take(1)).subscribe(async (data) => {
      this.family = data;
    });
  }

  getGuest(id: string): Observable<any> {
      this._guestService.getGuest(id).pipe(take(1)).subscribe(async(data) => {
        this.guestSelected = data;
        this.isGuestSelected = true;
        this.guestSelected.busInfo.trips.forEach(trip => {
          if(trip.busInfoCity != null){
            if(trip.type == TripType.Go){
              if(trip.busInfoCity.id != null){
                this.selectedCityGo = trip.busInfoCity.id;
              }
            }else{
              if(trip.busInfoCity.id != null){
                if(this.selectedCityBack != trip.busInfoCity.id){
                  this.getTimesBack(trip.busInfoCity.id);
                }
                this.selectedCityBack = trip.busInfoCity.id;
              }
              if(trip.busInfoTime.id != null){
                this.selectedTimeBack = trip.busInfoTime.id;
              }
            }
          }
        });

        this.feedInfoComments = this.guestSelected.feedInfo.comments;
    });

    return of(null);
  }

  async changeGuest(id: string) {
    this.changedAttendance = false;
    this.getGuest(id).subscribe(() =>{
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth",  block: "center" });
      }, 0);
    });
  }

  closeGuest(guestId: string){
    if(this.guestSelected.busInfo.isNeeded)
    {
      let countError = 0;
      this.guestSelected.busInfo.trips.forEach(trip => {
        if((trip.busInfoCity == null || trip.busInfoCity.id == null || trip.busInfoTime.id == null) && trip.isNeeded){
          countError++;
        }
      });

      if(countError > 0){
        this.busInfoErrorMessage = this._translocoService.translate('error-bus-info');
        return;
      }
    }

    if(this.busInfoErrorMessage != ""){
      this.busInfoErrorMessage = "";
    }

    setTimeout(() => {
      document.getElementById(guestId).scrollIntoView({ behavior: "smooth",  block: "center" });
    }, 0);

    this.guestSelected = null;
    this.isGuestSelected = false;
  }

  async getCities() {
    this._busInfoService.getCities().pipe(take(1)).subscribe(data => {
      this.busInfoCities = data;
    });
  }

  async getTimesBack(cityId: string) {
    this._busInfoService.getTimesBack(cityId).pipe(take(1)).subscribe(data => {
      this.busInfoTimes = data;
    });
  }

  putAttendance(id: string, attendanceType: number){
    this.changedAttendance = true;
    this._guestService.putAttendance(id, attendanceType).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putBusInfoIsNeeded(id: string, isNeeded: boolean){
    this._busInfoService.putBusInfoIsNeeded(id, isNeeded).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putBusTripIsNeeded(id: string, isNeeded: boolean){
    this._busInfoService.putBusTripIsNeeded(id, isNeeded).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putBusInfoCity(id: string, busInfoCityId: string, tripType: TripType){
    this._busInfoService.putCity(id, busInfoCityId).pipe(take(1)).subscribe(data => {

      if(tripType == TripType.Back){
        this.getTimesBack(busInfoCityId);
      }
      this.getGuest(this.guestSelected.id);
    });
  }

  putBusInfoTime(id: string, busInfoTimeId: string){
    this._busInfoService.putTime(id, busInfoTimeId).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putFeedInfoIsCeliac(id: string, isCeliac: boolean){
    this._feedInfoService.putFeedInfoIsCeliac(id, isCeliac).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putFeedInfoIsVegetarian(id: string, isVegetarian: boolean){
    this._feedInfoService.putFeedInfoIsVegetarian(id, isVegetarian).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putFeedInfoIsVegan(id: string, isVegan: boolean){
    this._feedInfoService.putFeedInfoIsVegan(id, isVegan).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putFeedInfoIsAllergic(id: string, isAllergic: boolean){
    this._feedInfoService.putFeedInfoIsAllergic(id, isAllergic).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
    });
  }

  putFeedInfoComments(id: string, comments: string){
    this._feedInfoService.putFeedInfoComments(id, comments).pipe(take(1)).subscribe(data => {
      this.getGuest(this.guestSelected.id);
      this.feedInfoCommentsAlertClosed = false;
      this.feedInfoCommentsVisibility = false;
      setTimeout(() => {
        this.feedInfoCommentsAlertClosed = true;
     }, 3000);
    });
  }

  putSendMessage(id: string, message: string){
    this._messagesServices.postMessage(id, message).pipe(take(1)).subscribe(data => {
      this.getFamily();
      this.message = "";
      this.sendMessageForm = false
    },(error: any) => {
      this.setMessageErrorMessage(error);
    });
  }

  async setMessageErrorMessage(error: any){
    if(error.error == "MaxReached"){
      this.messageErrorMessage = this._translocoService.translate('error-messages-max-reached');
    } else {
      this.messageErrorMessage = "Error en el servidor";
    }

    setTimeout(() => {
      this.messageErrorMessage = "";
    }, 3000);
  }

  setSongErrorMessage(error: any){
    if (error.error == "Duplicate"){
      this.songErrorMessage = this._translocoService.translate('error-song-duplicate');
    } else {
      this.songErrorMessage = "Error en el servidor";
    }
  }

  deleteMessage(familyId:string, id: string){
    let sureDeleteMessage = this.translocoService.translate('sure-delete-message');
    let deleteMessage = this.translocoService.translate('delete-message-title');
    this.modalService.open(deleteMessage, sureDeleteMessage, false).onClose().pipe(take(1),share()).subscribe(value => {
      if(value.responseButton == 1){
        this._messagesServices.deleteMessage(familyId, id).pipe(take(1)).subscribe(data => {
          this.getFamily();
        });
      }
    });
  }

  async getSongs(){
    this._songService.getSongs().pipe(take(1)).subscribe(data => {
      this.songs = data;
    });
  }

  postSong(){
    this._songService.postSong(this.song).pipe(take(1)).subscribe(data => {
      this.getSongs();
      this.sendSongForm = false;
      this.song = { artist: "", title: "" }
    },(error: any) => {
      this.setSongErrorMessage(error);
      setTimeout(() => {
        this.songErrorMessage = "";
     }, 3000);
    });
  }

  postCompanion(familyId: string, guestId: string){
    this._guestService.postCompanion(familyId, guestId, this.companion).pipe(take(1)).subscribe(data => {
      this.getFamily();
      this.sendCompanionForm = false;
      this.companion = { name: "", firstSurName: "", lastSurName: "" };
    },(error: any) => {
    });
  }

  deleteSong(id: string){
    let sureDeleteSong = this.translocoService.translate('sure-delete-song');
    let deleteSong = this.translocoService.translate('delete-song-title');
    this.modalService.open(deleteSong, sureDeleteSong, false).onClose().pipe(take(1),share()).subscribe(value => {
      if(value.responseButton == 1){
        this._songService.deleteSong(id).pipe(take(1)).subscribe(data => {
          this.getSongs();
        });
      }
    });
  }

  togglePdfViewer() {
    this.pdfViewerOpened = !(this.pdfViewerOpened);
  }
}
