import { Component, AfterViewInit } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.mjs';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements AfterViewInit {
    private pdfDocument: any;
    public currentPage: number = 1;
    public totalPages: number = 0;
  
    ngAfterViewInit() {
      const pdfUrl = '/assets/docs/salva-i-teresa-2024.pdf';
      const pdfContainer = document.getElementById('pdf-container')!;
      (pdfjsLib as any).GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.js';
  
      pdfjsLib.getDocument(pdfUrl).promise.then((pdf: any) => {
        this.pdfDocument = pdf;
        this.totalPages = pdf.numPages;
        this.renderPage(this.currentPage, pdfContainer);
      }).catch((error: any) => {
        console.error('Error al cargar el PDF:', error);
      });
    }
  
    renderPage(pageNumber: number, container: HTMLElement) {
      this.pdfDocument.getPage(pageNumber).then((page: any) => {
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        if (context) {
          page.render({
            canvasContext: context,
            viewport: viewport
          }).promise.then(() => {
            container.innerHTML = '';
            const pageWrapper = document.createElement('div');
            pageWrapper.style.boxShadow = '0px 0px 15px rgba(0, 0, 0, 0.1)';
            pageWrapper.style.display = 'inline-block';
            pageWrapper.style.margin = '10px';
  
            pageWrapper.appendChild(canvas);
            container.appendChild(pageWrapper);
          });
        }
      }).catch((error: any) => {
        console.error(`Error al cargar la página ${pageNumber}:`, error);
      });
    }
  
    showNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      } else {
        this.currentPage--;
      }
        const pdfContainer = document.getElementById('pdf-container')!;
        this.renderPage(this.currentPage, pdfContainer);
    }
  }