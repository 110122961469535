<!-- Page -->
<div class="gla_middle_titles">

  <!-- Slider -->
  <div class="gla_slider gla_image_bck gla_fixed" data-stellar-background-ratio="0.2" style="background-image: url(assets/images/landing.jpg); background-attachment: fixed; background-repeat:no-repeat; background-size: cover;background-position: middle;">

    <!-- Over -->
    <div class="gla_over" data-opacity="0"></div>

    <div class="container">

      <!-- Slider Texts -->
      <div class="gla_slide_txt gla_slide_center_middle text-center container_hero p-4" style="text-align: center;">
        <svg viewBox="-25 0 250 90" style="display: inline-block;">
          <defs>
            <style>.cls-1{font-size:50px;fill:#ffffff;font-family:perfectbeloved;letter-spacing:0.01em;}</style>
          </defs>
          <text id="Ens_casem" data-name="Ens casem" class="cls-1" transform="translate(2.3 86.91) scale(1.2 1)">
            Ens casem!
          </text>
        </svg>
        <div class="gla_slide_midtitle">
        SALVA I TERESA
        </div>
        <div class="m-4 p-4">
          <div class="gla_countdown" data-year='2025'  data-month='06' data-day='22'></div>
        </div>
      </div>
      <!-- Slider Texts End -->

    </div>
    <!-- container end -->

    <!-- Slide Down -->
    <a class="gla_scroll_down text-white">
      <b></b>
      <!--{{'scroll-label' | transloco }}-->
    </a>

  </div>
  <!-- Slider End -->


  <!-- Content -->
  <section id="gla_content" class="gla_content">

    <!-- section -->
    <section class="gla_section gla_lg_padding gla_image_bck gla_fixed gla_wht_txt" id="homepage-image-tint" data-stellar-background-ratio="0.2">

      <div class="container text-center">
        <div class="timeline">
          <div class="event">
            <span class="event-date">{{ 'landing-page-first-title-label' | transloco }}</span>
            <div class="event-content">
              <span class="gla_news_block">
                <span class="gla_news_img">
                    <span class="gla_over">
                      <img src="assets/images/formentera.png" alt="Formentera" class="img-fluid">
                    </span>  
                </span>
                <span class="col-12 text-justify text-center">
                  <span [innerHTML]="'landing-page-first-text-label' | transloco | markdown"></span>
                </span>
              </span>
            </div>
          </div>

          <div class="event">
            <span class="event-date">{{ 'landing-page-second-title-label' | transloco }}</span>
            <div class="event-content">
              <span class="gla_news_block">
                <span class="gla_news_img">
                    <span class="gla_over">
                      <img id="dynamicImage" src="assets/images/ibiza.jpg" class="img-fluid" data-index="0">
                    </span>  
                </span>
                <span class="col-12 text-justify text-center">
                  <span [innerHTML]="'landing-page-second-text-label' | transloco | markdown"></span>
                </span>
              </span>
            </div>
          </div>

          <div class="event">
            <span class="event-date">{{ 'landing-page-third-title-label' | transloco }}</span>
            <div class="event-content">
              <span class="gla_news_block">
                <span class="gla_news_img">
                    <span class="gla_over">
                      <img src="assets/images/anell.jpg" alt="Anell" class="img-fluid">
                    </span>
                </span>
                <span class="col-12 text-justify text-center">
                  <span [innerHTML]="'landing-page-third-text-label' | transloco | markdown"></span>
                </span>
                <span class="col-12 d-flex justify-content-center align-items-center">
                  <img src='assets/images/teresaisalva.png' class='img-fluid mx-auto'>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container text-center">
      <img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt="">
      <h1>{{ 'rsvp' | transloco }}</h1>
      <button class="btn my-data btn-secondary my-4" [routerLink]="['/p/rsvp']">
          {{ 'rsvp' | transloco }}
      </button>
      <br>
      <br>
      <br>
    </div>
    
  </section>
  <!-- Content End -->

</div>
<!-- Page End -->

